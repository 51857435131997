'use strict'
const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const constants = require('document-services-json-schemas/dist/constants.json')
const {DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} = constants
const affectedNameSpaces = [DATA_TYPES.theme, DATA_TYPES.design, DATA_TYPES.transformations, DATA_TYPES.transitions]

const isMobileCompQueryNonValid = (mobileComp, desktopComp, ns) => {
    const compId = mobileComp.id
    const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[ns]
    const mobileItemRef = mobileComp[queryKey]
    return mobileItemRef &&
        desktopComp &&
        mobileItemRef !== desktopComp[queryKey] &&
        (ns !== DATA_TYPES.design || mobileItemRef === `#${compId}`)
}

const compHasWrongMobileSplit = (mobileComp, desktopComps) => _.some(affectedNameSpaces, ns => {
    const compId = mobileComp.id
    const desktopComp = desktopComps[compId]
    return isMobileCompQueryNonValid(mobileComp, desktopComp, ns)
})

const fix = (mobileComps, desktopComps) => {
    _.forEach(mobileComps, (mobileComp, compId) => {
        const desktopComp = desktopComps[compId]
        _.forEach(affectedNameSpaces, ns => {
            if (isMobileCompQueryNonValid(mobileComp, desktopComp, ns)) {
                const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[ns]
                mobileComp[queryKey] = desktopComp[queryKey]
            }
        })
    })
}

/**
 * The fixer handles components that have mobile split on namespaces that should not be split,
 * according the following algorithm:
 * 1. get all desktop components as flat map (comp id -> comp)
 * 2. get all corrupted mobile comps using the map from phase 1
 * 3. remove the corrupted query
 * the affected namespaces are: style, design, transitions, transformations
 *
 *
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
        const {isExperimentOpen} = magicObject
        if (isExperimentOpen('dm_fixMobileSplitInVariantNs')) {
            try {
                const desktopComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, () => true)
                const shouldFixComponent = mobileComp => compHasWrongMobileSplit(mobileComp, desktopComps)
                const mobileComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, shouldFixComponent)
                fix(mobileComps, desktopComps)
            } catch (e) {
                //no bi in plugin fixers
                return false
            }
        }
    }
}
